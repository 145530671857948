html, body, main, #root {
    height: 100%;
    overflow-y: auto;
}

html {
    background-color: #E6E0D6; /* OLD: #d4d4a03d */
}

.App {
    text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

::-webkit-scrollbar {
    width: 5px !important;
    height: 5px !important;
}

/* Track */
::-webkit-scrollbar-track {
    border-radius: 5px !important;
}


/* Handle */
::-webkit-scrollbar-thumb {
    background: #d1d1d1 !important;
    border-radius: 5px !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #292f3ec7 !important;
}

.MuiPaper-elevation1 {
    box-shadow: none !important;
}

/* disable google auto fill blue */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #e6e0d6 inset !important;
}